import { alpha, Theme } from "@material-ui/core";
import { OperatorStatusType, OperatorAlertType } from "interfaces";

interface OperatorStatusColorsProps {
  theme: Theme;
  statusType?: OperatorStatusType;
  color?: boolean;
  opacityColor?: boolean;
}

interface OperatorAlertColorsProps {
  theme: Theme;
  statusType?: OperatorAlertType;
  color?: boolean;
  opacityColor?: boolean;
}

export const operatorStatusColors = ({
  theme,
  statusType,
  color,
  opacityColor,
}: OperatorStatusColorsProps): string => {
  if (!statusType) return OperatorStatusType.NOT_AVAILABLE;
  if (color) {
    return statusType === OperatorStatusType.MANUAL
      ? theme.palette.info.main
      : statusType === OperatorStatusType.AVAILABLE
      ? theme.palette.success.main
      : statusType === OperatorStatusType.CT_AVAILABLE
      ? theme.palette.success.main
      : statusType === OperatorStatusType.PRE_ASSEMBLED 
      ? theme.palette.warning.main
      : statusType === OperatorStatusType.CT_MANUAL 
      ? theme.palette.warning.main
      : statusType === OperatorStatusType.ASSEMBLED
      ? theme.palette.error.main
      : statusType === OperatorStatusType.CT_ASSEMBLED
      ? theme.palette.error.main
      : statusType === OperatorStatusType.RELAY 
      ? theme.palette.warning.main
      : theme.palette.text.primary;
  }
  if (opacityColor) {
    return statusType === OperatorStatusType.MANUAL
      ? alpha(theme.palette.info.main, 0.08)
      : statusType === OperatorStatusType.AVAILABLE
      ? alpha(theme.palette.success.main, 0.08)
      : statusType === OperatorStatusType.CT_AVAILABLE
      ? alpha(theme.palette.success.main, 0.08)
      : statusType === OperatorStatusType.PRE_ASSEMBLED
      ? alpha(theme.palette.warning.main, 0.08)
      : statusType === OperatorStatusType.CT_MANUAL
      ? alpha(theme.palette.warning.main, 0.08)
      : statusType === OperatorStatusType.ASSEMBLED
      ? alpha(theme.palette.error.main, 0.08)
      : statusType === OperatorStatusType.CT_ASSEMBLED
      ? alpha(theme.palette.error.main, 0.08)
      : statusType === OperatorStatusType.RELAY
      ? alpha(theme.palette.warning.main, 0.08)
      : alpha(theme.palette.action.disabled, 0.08);
  }
  return OperatorStatusType.NOT_AVAILABLE;
};

export const operatorAlertColors = ({
  theme,
  statusType,
  color,
}: OperatorAlertColorsProps): string => {
  if (color) {
    return statusType === OperatorAlertType.UNABLE
      ? theme.palette.error.main
      : theme.palette.warning.main;
  }

  return OperatorStatusType.NOT_AVAILABLE;
};
