import { Badge, Box, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core'
import { Chip, OperatorIcon, TriangleIcon} from 'components';
import { BusIcon } from 'components/icons/BusIcon';
import { GreenTicketIcon } from 'components/icons/GreenTicket';
import { ShovelIcon } from 'components/icons/ShovelIcon';
import { UbicationIcon } from 'components/icons/UbicationIcon';
import { OperatorsInfo } from 'contexts/ShiftChangeOperatorsContext';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react'

interface IDataOperatorsTable {
  dataOperators:  OperatorsInfo[],
  type: string | null
}

export const OperatorsTable: React.FC<IDataOperatorsTable> = ({
  dataOperators, type
}) => {
    const classes = useStyles();

    const iconInfo = (type: string) => {
      if (type === 'OPERATOR'){
        return <OperatorIcon className={classes.icon} />
      }
      if(type === 'BUS') {
        return <BusIcon className={classes.icon} />
      }
      if(type === 'UBICATION') {
        return <UbicationIcon className={classes.icon} />
      }
      else {
        return <></>
      }
    }
    const iconTitle = (title: string, type: string) => {
      return (
        <Box className={classes.headerBox}>
            <Badge
                overlap="circular"
                classes={{
                    badge: classes.operatorBadge,
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                >
                {iconInfo(type)}
            </Badge>
            <Typography
              className={classes.subTitle}
              noWrap
            >
                {title}
            </Typography>
            </Box>
      )
    }
    const palaIcon = (equipType: string, type: string | null) => {
      if (equipType === 'Palas') {
        return <ShovelIcon height={24} width={24} />
      }
      if (type === 'SIN-ASIGNACION' && equipType !== '') {
        return <GreenTicketIcon height={24} width={24} />
      }
      else {
        return <></>
      }
    }

    const not_asigned_oper = (bus: string) => {
      if (bus === 'NO ASIGNADO'){
        return (
        <Grid container className={classes.chipIcon}>
          <WarningIcon style={{width: '20px', height: '20px', color: '#ED6C02'}} />
          <Typography style={{color: '#ED6C02', fontSize: '14px', fontWeight: 'bold',
            paddingLeft: '16px'
          }} >
            NO ASIGNADO
          </Typography>
        </Grid>)
      }else {
        return (
          <Typography className={classes.text}>
            {bus}
          </Typography>
        )
      }
    }

    const no_ubication = (bus: string) => {
      if (bus === 'NINGUNA'){
        return (
        <Grid container className={classes.noneChipIcon}>
          <WarningIcon style={{width: '20px', height: '20px', color: '#ED6C02'}} />
          <Typography style={{color: '#ED6C02', fontSize: '14px', fontWeight: 'bold', paddingLeft: '16px'}} >
              NINGUNA
            </Typography>
        </Grid>)
      }else {
        return (
          <Typography className={classes.textName}>
            {bus}
          </Typography>
        )
      }
    }
  return (
    <Paper elevation={0} className={classes.operatorPaper}>
        <Grid container className={classes.titleGrid}>
          <Grid item style={{width: '45%'}} className={classes.titleIcon}>
            {iconTitle('NOMBRE DEL OPERADOR', 'OPERATOR')}
          </Grid>
          <Grid item style={{width: '25%'}} className={classes.titleIcon}>
          {iconTitle('BUS', 'BUS')}
          </Grid>
          <Grid item style={{width: '30%'}} className={classes.titleIcon}>
          {iconTitle('UBICACIÓN', 'UBICATION')}
          </Grid>
        </Grid>
        <Grid container>
          <TableContainer className={classes.table}>
            <Table stickyHeader size="small">
              <TableBody>
                {dataOperators.map( (data, index) => (
                  <TableRow key={ `${data.name}-${data.bus}-${data.ubication}-${index}` } 
                  className={classes.tableRow}
                  >
                  <TableCell className={classes.tableCell}
                    style={{width: '45%'}}
                  >
                    <Grid container spacing={1}>
                      <Grid item>
                      {palaIcon(data.equipType, type)}
                      </Grid>
                      <Grid item>
                        <Typography className={classes.textName}>
                  	      {data.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell className={classes.tableCellBus}
                    style={{width: '25%'}}
                  >
                  	{not_asigned_oper(data.bus)}
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    style={{width: '30%'}}
                  >
                    <Typography className={classes.text}>
                      {no_ubication(data.ubication)}
                    </Typography>
                  </TableCell>
                </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        
    </Paper>  )
}


const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  const { type } = palette;
    return {
      operatorPaper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        [theme.breakpoints.down('md')]: {
          minWidth: 600,
          maxWidth: 650
        },
        width: 850,
        paddingLeft: 30,
        paddingRight: 46,
        backgroundColor:
          type === "light"
            ? theme.palette.background.default
            : theme.palette.background.paper,
        paddingBottom: 32,
      },
      titleGrid: {
        dsplay: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        paddingBottom: 8,
        height: 104
      },
      titleIcon: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start'
      },
      headerBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 25,
        paddingBottom: 22
      },
      operatorBadge: {
        marginRight: 7,
        marginBottom: 3,
        height: 15,
        width: 15,
        minWidth: 5,
        backgroundColor: palette.text.primary,
      },
      icon: {
        width: 60,
        height: 60,
        marginRight: 8,
      },
      subTitle: {
        fontWeight: "bold",
        fontSize: '18px',
        [theme.breakpoints.down('md')]: {
          fontSize: '14px',
        },
      },
      chipIcon: {
        borderRadius: '20px',
        backgroundColor: '#FDF0E5',
        width: 154,
        paddingBottom: 2,
        paddingTop: 2,
        paddingLeft: 6,
        paddingRight: 6,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      },
      noneChipIcon: {
        borderRadius: '20px',
        backgroundColor: '#FDF0E5',
        maxWidth: 130,
        alignItems: 'center'
      },
      table: {
        paddingBottom: 24
      },
      tableRow: {
        height: 43,
        margin: 0,
        padding: 0,
        borderLeft: `1px solid ${type === 'dark' ?
          palette.background.default :
          palette.info.contrastText}`,
        borderRight: `1px solid ${type === 'dark' ?
          palette.background.default :
          palette.info.contrastText}`,
        '&:nth-of-type(even)': {
          backgroundColor: 
          type === 'dark' ?
          palette.background.default :
          palette.info.contrastText
        }
      },
      tableCell: {
        border: `1px solid ${type === 'dark' ?
          palette.background.default :
          palette.info.contrastText}`,

      },
      tableCellBus: {
        border: `1px solid ${type === 'dark' ?
          palette.background.default :
          palette.info.contrastText}`,
      },
      textName: {
        textAlign: `left`,
        fontSize: '20px',
        [theme.breakpoints.down('md')]: {
          fontSize: '14px',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '24px',
        },
        fontWeight: 'normal',
        fontFamily: 'Roboto'
      },
      text: {
        fontSize: '18px',
        [theme.breakpoints.down('md')]: {
          fontSize: '14px',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '22px',
        },
        textAlign: 'center',
        fontWeight: 'normal',
        fontFamily: 'Roboto'
      },
      
    }
});