import React, { useCallback, useMemo } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  makeStyles,
  Theme,
} from "@material-ui/core";
import {
  CardAlertType,
  ColumnGroupType,
  ColumnType,
  PreTruckSummary,
  TruckSummary,
} from "interfaces";
import { TruckInfoCard, TruckTimeInfoCard, OperatorInfoCard } from "../";
import { RefetchFunctionType, useMinutesCounter } from "hooks";
import { PreAssembledTruckTimeInfoCard } from "./PreAssembledTruckInfoCard";
import { PreAssembleTruckInfoCard } from "./PreAssembleTruckInfoCard";
import { PreAssembleOperatorInfoCard } from "./PreAssembleOperatorInfoCard";

interface TruckCardProp {
  truck: PreTruckSummary;
  type: ColumnType;
  groupType: ColumnGroupType;
  onClick: (truck: TruckSummary, groupType: ColumnGroupType) => void;
  refetch: RefetchFunctionType<PreTruckSummary[], unknown>
}

export const PreAssembledTruckCard: React.FC<TruckCardProp> = ({
  truck,
  type,
  groupType,
  onClick,
  refetch
}) => {
  const [sinceMinutes] = useMinutesCounter(truck.issueDate);
  const [remainingMinutes] = useMinutesCounter(
    truck.releaseProjectionTime,
    true
  );

  const alertType = useMemo<CardAlertType>(
    () => truck.alertType ?? CardAlertType.DEFAULT,
    [truck.alertType]
  );

  // Logic
  const handleOnCardClick = useCallback(
    () => onClick(truck, groupType),
    [onClick, truck, groupType]
  );

  const classes = useStyles({ alertType });

  return (
    <Card className={classes.card}>
      <CardActionArea >
        <CardContent className={classes.cardContent}>
          <div className={classes.truckCardInfoRoot} onClick={handleOnCardClick}>
            <PreAssembleTruckInfoCard
              truck={truck}
              alertType={alertType}
              showReasonId={groupType === ColumnGroupType.MAINTENANCE}
            />
            <PreAssembleOperatorInfoCard truck={truck} alertType={alertType} />
          </div>
          <div className={classes.truckProyectionInfoRoot}>
            <PreAssembledTruckTimeInfoCard
              sinceTime={sinceMinutes}
              remainingTime={remainingMinutes}
              columnType={type}
              alertType={alertType}
              releaseTime={truck.releaseProjectionTime}
              groupType={groupType}
              projectionType={truck.releaseProjectionType}
              preAssembled={true}
              avisado={truck.avisado}
              refetch={refetch}
              truck={truck}
            />
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

interface StyleProps {
  alertType: CardAlertType;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  card: {
    backgroundColor: theme.palette.background.default,
    maxWidth: 361,
    marginLeft: 16,
    marginRight: 15,
    marginBottom: 15,
    [theme.breakpoints.down(1441)]: {
      marginLeft: 7,
      marginRight: 7,
    },
  },
  cardContent: {
    display: "flex",
    padding: 4,
  },
  truckCardInfoRoot: {
    maxWidth: 212,
  },
  truckProyectionInfoRoot: {
    width: "100%",
    position: "relative", 
  },
}));
