import { makeStyles, Paper, Typography } from "@material-ui/core";
import {
  TruckSummary,
  Location,
  TruckEditableInfo,
  ColumnGroupType,
  Can,
  Module,
  TruckEditionRequestBody,
  ErrorMsg,
} from "interfaces";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Restricted, TruckAvatar } from "components";
import {
  LocationOnOutlined,
  ChatOutlined,
  TimerOutlined,
  BuildOutlined,
  PersonOutlineOutlined,
} from "@material-ui/icons";
import { TruckDetailItem } from "./TruckDetailItem";
import { TruckDetailItemSelector } from "./TruckDetailItemSelector";
import { TruckDetailItemTextField } from "./TruckDetailItemTextField";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { TruckDetailItemDateTimePicker } from "./TruckDetailItemDateTimePicker";
import { formatLocalizedDate, formatMaintenanceLevels } from "utils";
import { RefetchFunctionType, useData, useDebouncedCallback } from "hooks";
import { UserContext } from "contexts";
import { logError } from "services";
import { useSnackbar } from "notistack";

interface TruckDetailsCardProps {
  truck: TruckSummary;
  updateTruck: RefetchFunctionType<unknown, TruckEditionRequestBody>;
  preAssemble: boolean;
}

export const TruckDetailsCard: React.FC<TruckDetailsCardProps> = ({
  truck,
  updateTruck,
  preAssemble
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { isAllowedTo } = useContext(UserContext);
  const canUpdate = isAllowedTo(Can.WRITE, Module.OPERATOR_ASSIGNMENT);

  const { data: locations } = useData<Location[]>(
    {
      config: {
        url: "/operator-assignment/locations",
        params: {
          entity: "equipment",
        },
      },
    },
    ErrorMsg.GET_LOCATIONS
  );

  //Logic
  const saveChanges = useDebouncedCallback(
    async (details: TruckEditableInfo) => {
      try {
        await updateTruck({
          data: {
            assignmentComment: details.assignmentComment,
            locationId: details.location?.id,
            proyection: details.proyection ?? "",
          },
        });
        enqueueSnackbar(`Cambios guardados exitosamente`, {
          variant: "success",
        });
      } catch (e) {
        logError("TRUCK-DETAILS-SCREEN", `Error al actualizar datos: ${e}`);
      }
    },
    500
  );

  //Local state
  const [truckEditableInfo, setTruckEditableInfo] = useState<TruckEditableInfo>(
    () => ({
      location: truck.location,
      proyection: truck.releaseProjectionTime
        ? new Date(truck.releaseProjectionTime)
        : null,
      assignmentComment: truck.userComment ?? null,
    })
  );

  //Logic
  const truckCodeAndFleet = useMemo(
    () =>
      `${truck?.id ?? "-"} | ${truck?.reasonId ?? "-"} | ${
        truck?.model ?? "-"
      }`,
    [truck]
  );

  const onLocationChange = useCallback(
    (e: React.ChangeEvent<{ value: unknown }>) => {
      const selectedValue = e.target.value as number;
      const newLocation = locations?.find((l) => l.id === selectedValue);

      setTruckEditableInfo((prev) => {
        const newState = {
          ...prev,
          location: newLocation,
        };
        // Try to save the changes (debounced)
        saveChanges(newState);
        // Update the state
        return newState;
      });
    },
    [locations, saveChanges /*truckEditableInfo*/]
  );

  const onTimeChange = useCallback(
    (date: MaterialUiPickersDate) => {
      setTruckEditableInfo((prev) => {
        const newState = {
          ...prev,
          proyection: date,
        };
        saveChanges(newState);
        return newState;
      });
    },
    [saveChanges]
  );

  const onCommentChange = useCallback(
    (
      e: React.ChangeEvent<{
        value: unknown;
      }>
    ) => {
      setTruckEditableInfo((prev) => {
        const newState = {
          ...prev,
          assignmentComment: e.target.value as string,
        };
        saveChanges(newState);
        return newState;
      });
    },
    [saveChanges]
  );

  return (
    <Paper className={classes.truckDetailsPaper} elevation={0}>
      <div className={classes.leftDetails}>
        <TruckAvatar
          truck={truck}
          classes={{
            avatar: classes.avatar,
            caText: classes.caText,
            ppmAvatar: classes.ppmAvatar,
            ppmAvatarTitle: classes.ppmAvatarTitle,
            ppmAvatarSubTitle: classes.ppmAvatarSubTitle,
            icon: classes.truckIcon,
          }}
        />
        <Typography className={classes.truckCodeAndFleet} variant="h6" noWrap>
          {truckCodeAndFleet}
        </Typography>
      </div>
      <Paper className={classes.rightDetails} elevation={0}>
        <div className={classes.detailSection}>
          {canUpdate ? (
            <TruckDetailItemSelector
              onChange={onLocationChange}
              icon={LocationOnOutlined}
              label="Ubicación"
              value={
                locations.length ? truckEditableInfo.location?.id : undefined
              }
              options={locations ?? []}
            />
          ) : (
            <TruckDetailItem
              icon={LocationOnOutlined}
              label="Ubicación"
              text={truckEditableInfo.location?.name ?? "-"}
            />
          )}
          {preAssemble ? null :
          <Restricted to={truck.group === ColumnGroupType.MAINTENANCE}>
            <>
              <TruckDetailItem
                icon={BuildOutlined}
                label="Mantención"
                text={formatMaintenanceLevels(truck.maintenance) || "-"}
              />
              <TruckDetailItem
                icon={ChatOutlined}
                label="Detalle"
                text={truck.maintenance?.comment ?? "-"}
              />
            </>
          </Restricted>
          }
        </div>
        <div className={classes.detailSection}>
          {preAssemble ? null :
          
          <Restricted to={truck.group === ColumnGroupType.MAINTENANCE}>
            {canUpdate ? (
              <TruckDetailItemDateTimePicker
                onChange={onTimeChange}
                icon={TimerOutlined}
                label="Salida"
                value={truckEditableInfo.proyection}
              />
            ) : (
              <TruckDetailItem
                icon={TimerOutlined}
                label="Salida"
                text={
                  formatLocalizedDate(
                    truckEditableInfo.proyection,
                    "dd 'de' MMMM, HH:mm 'hrs.'"
                  ) ?? "-"
                }
              />
            )}
          </Restricted>
            }
          <TruckDetailItem
            icon={PersonOutlineOutlined}
            label={truck.currentOper ? "Operador actual" : "Último operador"}
            text={truck.currentOper?.name ?? truck.lastOper?.name ?? "-"}
          />
        </div>
        <div className={classes.detailSection}>
          {canUpdate ? (
            <TruckDetailItemTextField
              onChange={onCommentChange}
              icon={ChatOutlined}
              label="Comentario"
              value={truckEditableInfo.assignmentComment}
            />
          ) : (
            <TruckDetailItem
              icon={ChatOutlined}
              label="Comentario"
              text={truckEditableInfo.assignmentComment ?? "-"}
            />
          )}
        </div>
      </Paper>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    truckDetailsPaper: {
      display: "flex",
      padding: 16,
      backgroundColor: theme.palette.background.default,
    },
    leftDetails: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: 35,
      marginRight: 43,
      minWidth: 280,
    },
    rightDetails: {
      backgroundColor: theme.palette.background.paper,
      maxWidth: 1490,
      width: "100%",
      padding: "0px 21px 0px 21px",
    },
    detailSection: {
      display: "flex",
      flexWrap: "wrap",
    },
    truckCodeAndFleet: {
      fontWeight: 700,
    },
    truckIcon: {
      width: 87,
      height: 80,
    },
    avatar: {
      width: 64,
      height: 64,
      backgroundColor: theme.palette.avatar.background,
      color: theme.palette.getContrastText(theme.palette.avatar.background),
      marginBottom: 10,
    },
    caText: {
      fontSize: "2.125rem",
    },
    ppmAvatarTitle: {
      fontSize: 22,
      lineHeight: "unset",
    },
    ppmAvatarSubTitle: {
      fontSize: 20,
      lineHeight: "18px",
      fontWeight: "normal",
    },
    ppmAvatar: {
      width: 66,
      height: 66,
    },
  };
});
