import React, { useMemo } from "react";
import { Paper, Typography, makeStyles, Theme, alpha, Switch, FormControlLabel, Grid } from "@material-ui/core";
import {
  CardAlertType,
  ColumnType,
  ColumnGroupType,
  TruckProjectionType,
  PreTruckSummary,
  ErrorMsg,
} from "interfaces";
import {
  getAlertPaperColor,
  getAlertTextColor,
  getAlertTimeTextColor,
} from "styles";
import { parseTimeDelta } from "utils";
import format from "date-fns/format";
import { CornerLabel } from "../CornerLabel";
import { RefetchFunctionType, useData } from "hooks";

enum TimeType {
  SINCE = "since",
  REMAINING = "remaining",
}

interface AvisadoRequestBody {
  operId: string;
  equipId: string;
  warned: number;
}

interface TruckTimeInfoCardProps {
  columnType: ColumnType;
  sinceTime: number | null;
  remainingTime: number | null;
  releaseTime: string | null;
  alertType: CardAlertType;
  projectionType: TruckProjectionType;
  groupType: ColumnGroupType;
  preAssembled?: boolean;
  avisado?: number | null;
  refetch: RefetchFunctionType<PreTruckSummary[], unknown>
  truck: PreTruckSummary;
}

export const PreAssembledTruckTimeInfoCard: React.FC<TruckTimeInfoCardProps> = ({
  sinceTime,
  remainingTime,
  releaseTime,
  columnType,
  alertType,
  projectionType,
  groupType,
  preAssembled=false,
  avisado,
  refetch,
  truck
}) => {
  const classes = useStyles({ alertType });

  const { refetching: avisadoLoading, refetch: updateAvisado } = useData<
  unknown,
  AvisadoRequestBody
>(
  {
    config: {
        url: "/operator-assignment/assignments/pre-assignment/warned",
        method: "POST",
    },
    options: {
      manual: true,
    },
  },
  ErrorMsg.ASSIGN_OPERATOR
);

  const handleChangeAvisado = async() => {
    const params: AvisadoRequestBody = {
      equipId: truck.id,
      operId: truck.preassembledOper.id,
      warned: avisado === 0 ? 1 : 0
    }
    await updateAvisado({
      data: params
    })
    refetch()
  }

  const showCornerLabel = useMemo(
    () => groupType === ColumnGroupType.MAINTENANCE && projectionType != null,
    [projectionType, groupType]
  );

  const remainingTimeDetail = useMemo(
    () => ({
      showInfo: showTimeDetail(columnType, TimeType.REMAINING, false),
      text:
        columnType !== 'PENDING' ?
            'Operador avisado'
          : "Proyección",
    }),
    [columnType, projectionType]
  );

  const remainingTimeTextDetail = useMemo(
    () =>
      `${releaseTime ? format(new Date(releaseTime), "HH':'mm") : "-"} ${
        remainingTime === 0
          ? ""
          : ` | ${remainingTime !== null ? parseTimeDelta(remainingTime) : "-"}`
      }`,
    [releaseTime, remainingTime]
  );

  const avisadoSwitch = () => {
    return (
        <FormControlLabel
              key={`label`}
              className={classes.switchLabel}
              control={
                <Switch
                  color="primary"
                  checked={avisado === 1 ? true : false}
                  onChange={(e) => {
                      e.stopPropagation()
                      handleChangeAvisado()
                  }
                  }
                  name={'Avisado'}
                />
              }
              label={avisado === 1 ? 'Si' : 'No'}
              labelPlacement="end"
            />
            )
  }

  const sinceTimeDetail = useMemo(
    () => ({
      showInfo: showTimeDetail(columnType, TimeType.SINCE, false),
      text: "Hace",
    }),
    [columnType]
  );

  return (
    <Paper elevation={0} className={classes.timeInfoPaper}>
      {sinceTimeDetail.showInfo && (
        <div className={classes.timeInfoSection}>
          <div>
            <Typography className={classes.title} noWrap variant="body2">
              {sinceTimeDetail.text}
            </Typography>
              <Typography className={classes.title} noWrap variant="h6">
                {sinceTime !== null ? parseTimeDelta(sinceTime) : "-"}
              </Typography>
          </div>
        </div>
      )}
            {avisado === 0 || avisado === 1 ? 
            <Grid>
            <Typography className={classes.title} noWrap variant="body2">
              Operador avisado
            </Typography>
                {avisadoSwitch()}
            </Grid>
            : null}
      {truck.afterData === 1 ?
        <CornerLabel alertType={alertType} type={projectionType} afterData={truck.afterData} />
    : showCornerLabel && (
        <CornerLabel alertType={alertType} type={projectionType} />
      )}
    </Paper>
  );
};

interface StyleProps {
  alertType: CardAlertType;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  timeTitle: {
    color: theme.palette.text.primary,
  },
  title: {
    color: alpha(theme.palette.text.primary, 0.6),
  },
  timeInfoPaper: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    flexDirection: "column",
    padding: 4,
    height: "100%",
  },
  timeInfoSection: {
    display: "flex",
    justifyContent: "space-between",
  },
  onlyRemainingTimeAlarmRoot: {
    display: "flex",
    flexDirection: "column-reverse",
    height: "100%",
    alignSelf: "flex-end",
  },
}));

const showTimeDetail = (
  columnType: ColumnType,
  timeType: TimeType,
  showAlert: boolean
): boolean => {
  if (columnType === ColumnType.PENDING) {
    return showAlert || !!timeType;
  }
  if (columnType === ColumnType.PRE_ASSEMBLED) return true;
  if (
    [ColumnType.NO_OPERATOR, ColumnType.UNPLANNED_WAIT].includes(columnType)
  ) {
    return timeType === TimeType.SINCE;
  }
  return true;
};
