import { Bus } from "./bus.interface";
import { Location } from "./location.interface";
import { Truck } from "./truck.interface";

export enum OperatorStatusType {
  AVAILABLE = "AVAILABLE",
  PRE_ASSEMBLED = "PRE_ASSEMBLED",
  ASSEMBLED = "ASSEMBLED",
  NOT_AVAILABLE = "NOT_AVAILABLE",
  MANUAL = "MANUAL",
  RELAY = "RELAY",
  CT_AVAILABLE = "CT_AVAILABLE",	
  CT_MANUAL = "CT_MANUAL",
  CT_ASSEMBLED = "CT_ASSEMBLED",
}

export enum OperatorAlertType {
  OK = "OK",
  WARNING = "WARNING",
  UNABLE = "UNABLE",
  BLOCKED = "BLOCKED"
}

export const AlertOperatorType = [
  {"alert": OperatorAlertType.OK, "textOperatorAssig": "", "textOperator": ""},
  {"alert": OperatorAlertType.WARNING, "textOperatorAssig": `No ha operado el modelo {model} en 4 meses`, "textOperator": "Competencias prontas a vencer"},
  {"alert": OperatorAlertType.UNABLE, "textOperatorAssig": `No ha operado el modelo {model} en más de 6 meses`,  "textOperator": "Competencias vencidas"},
  {"alert": OperatorAlertType.BLOCKED, "textOperatorAssig": ``,  "textOperator": ""}
]

export interface Models {
  model: string;
  alert: OperatorAlertType;
  month?: number;
}

export const statusTranslation: Record<OperatorStatusType, string> = {
  [OperatorStatusType.AVAILABLE]: "DISPONIBLE",
  [OperatorStatusType.PRE_ASSEMBLED]: "PRE-ARMADO",
  [OperatorStatusType.ASSEMBLED]: "ARMADO",
  [OperatorStatusType.NOT_AVAILABLE]: "NO DISPONIBLE",
  [OperatorStatusType.MANUAL]: "DISP. MANUAL",
  [OperatorStatusType.RELAY]: "EN RELEVO",
  [OperatorStatusType.CT_AVAILABLE]: "DISPONIBLE",
  [OperatorStatusType.CT_MANUAL]: "CT MANUAL",
  [OperatorStatusType.CT_ASSEMBLED]: "CT ARMADO",
};

export interface Operator {
  id: string;
  name: string;
  area: string;
  status: OperatorStatusType;
  preferredEquip?: Pick<Truck, "id">;
  currentEquip?: Pick<Truck, "id" | "reasonId">;
  preassembledEquip?: Pick<Truck, "id">;
  location?: Location & {
    isAutomatic: boolean;
  };
  bus?: Bus;
  isInstructor: boolean;
  qualifs?: {
    models: Models[];
    count: number;
    maxAlert: { type: OperatorAlertType, count: number }
  };
}

export interface OperatorEditableInfo {
  operId: string;
  locationId?: number;
  busId?: number;
  isInstructor: boolean;
}

export enum RefetchOperatorsReason {
  ADDED = "ADDED",
  UPDATED = "UPDATED",
  DELETED = "DELETED",
}

export type RefetchOperatorsFunctionType = (
  reason: RefetchOperatorsReason
) => Promise<void>;
