import React, { useContext } from "react";
import { PreAssembleOperatorAssignmentKpisContext } from "contexts";
import { KpisPaper } from "./KpisPaper";
import { KpiCard } from ".";

export const PreAssembleOperatorAssignmentKpis: React.FC = () => {
  const { data, firstLoading } = useContext(PreAssembleOperatorAssignmentKpisContext);

  return (
    <KpisPaper loading={firstLoading}>
      {data.map((entry, i) => (
        <KpiCard key={`${entry.type}-${i}`} {...entry} /> 
      ))}
    </KpisPaper>
  );
};
