import {
  ColumnGroupType,
  ColumnType,
  ColumnGroupMapping,
  TruckSummary,
  DashboardTrucksData,
  TruckProjectionType,
  PreTruckSummary,
} from "interfaces";
import { orderBy } from "lodash";
import { formatLocalizedDate } from "./date";
import * as xlsx from "xlsx";
import { differenceInSeconds, isBefore } from "date-fns";

export const generateTrucksExcel = (trucks: TruckSummary[]) => {
  const maintenanceData = maintenanceTrucksExcelFormat(trucks);
  const detentionData = detentionTrucksExcelFormat(trucks);

  const workbook = xlsx.utils.book_new();
  const maintenanceSheet = xlsx.utils.json_to_sheet(maintenanceData);
  xlsx.utils.sheet_add_aoa(
    maintenanceSheet,
    [
      [
        "CAEX",
        "NOMBRE OPERADOR PRE-ARMADO",
        "UBICACIÓN CAEX",
        "",
        "COMENTARIOS MANTENCIÓN",
        "EMPRESA CONTRATISTA",
      ],
    ],
    { origin: "A1" }
  );

  const detentionSheet = xlsx.utils.json_to_sheet(detentionData);
  xlsx.utils.sheet_add_aoa(
    detentionSheet,
    [["CAEX", "NOMBRE OPERADOR PRE-ARMADO", "UBICACIÓN CAEX", "EMPRESA CONTRATISTA"]],
    { origin: "A1" }
  );

  xlsx.utils.book_append_sheet(workbook, maintenanceSheet, "MANTENCIONES");
  xlsx.utils.book_append_sheet(workbook, detentionSheet, "DETENCIONES");
  const timestamp = formatLocalizedDate(new Date())?.replace(/:/g, "") ?? "";
  xlsx.writeFile(workbook, `${timestamp}-caex-data.xlsx`);
};

export const generateTrucksExcelPreAssignment = (trucks: PreTruckSummary[]) => {
  const maintenanceData = maintenanceTrucksPreAssignmentExcelFormat(trucks);
  const detentionData = detentionTrucksPreAssignmentExcelFormat(trucks);

  const workbook = xlsx.utils.book_new();
  const maintenanceSheet = xlsx.utils.json_to_sheet(maintenanceData);
  xlsx.utils.sheet_add_aoa(
    maintenanceSheet,
    [
      [
        "CAEX",
        "NOMBRE OPERADOR PRE-ARMADO",
        "UBICACIÓN CAEX",
        "",
        "COMENTARIOS MANTENCIÓN",
        "AVISADO",
      ],
    ],
    { origin: "A1" }
  );

  const detentionSheet = xlsx.utils.json_to_sheet(detentionData);
  xlsx.utils.sheet_add_aoa(
    detentionSheet,
    [["CAEX", "NOMBRE OPERADOR PRE-ARMADO", "UBICACIÓN CAEX", "AVISADO"]],
    { origin: "A1" }
  );

  xlsx.utils.book_append_sheet(workbook, maintenanceSheet, "MANTENCIONES");
  xlsx.utils.book_append_sheet(workbook, detentionSheet, "DETENCIONES");
  const timestamp = formatLocalizedDate(new Date())?.replace(/:/g, "") ?? "";
  xlsx.writeFile(workbook, `${timestamp}-caex-data.xlsx`);
};

export const sortTrucks = (
  trucks: TruckSummary[],
  columnGroupType: ColumnGroupType
): TruckSummary[] =>
  columnGroupType === ColumnGroupType.DETENTIONS
    ? orderBy(trucks, ["issueDate", "id"], ["asc", "asc"])
    : orderBy(
        trucks,
        [
          (t) => t.isCa,
          (t) =>
            t.releaseProjectionType === TruckProjectionType.MANUAL &&
            !!t.releaseProjectionTime &&
            t.isReleaseProjectionWithinCurrentShift &&
            isBefore(new Date(t.releaseProjectionTime), new Date()) &&
            differenceInSeconds(new Date(), new Date(t.releaseProjectionTime)),
          "issueDate",
          "id",
        ],
        ["asc", "desc", "desc", "asc"]
      );

export const sortPreAssignmentTrucks = (
  trucks: PreTruckSummary[],
  columnGroupType: ColumnGroupType
): PreTruckSummary[] =>
  columnGroupType === ColumnGroupType.DETENTIONS
    ? orderBy(trucks, ["issueDate", "id"], ["asc", "asc"])
    : orderBy(
        trucks,
        [
          (t) => t.isCa,
          (t) =>
            t.releaseProjectionType === TruckProjectionType.MANUAL &&
            !!t.releaseProjectionTime &&
            t.isReleaseProjectionWithinCurrentShift &&
            isBefore(new Date(t.releaseProjectionTime), new Date()) &&
            differenceInSeconds(new Date(), new Date(t.releaseProjectionTime)),
          "issueDate",
          "id",
        ],
        ["asc", "desc", "desc", "asc"]
      );

export const generateFilteredTrucksData = (
  trucks: TruckSummary[]
): DashboardTrucksData => {
  return Object.values(ColumnGroupType).map((group) => ({
    group,
    columns: Object.values(ColumnType)
      .filter((col) => ColumnGroupMapping[col] === group)
      .map((column) => ({
        column,
        trucks: sortTrucks(
          trucks.filter((t) => t.group === group && t.column === column),
          group
        ),
      })),
  }));
};

export const filterDashboardTrucks = (
  isSearching: boolean,
  searchResults: TruckSummary[]
): TruckSummary[] => {
  if (isSearching) return searchResults;
// Filter Trucks
 return searchResults.filter(
    (t) =>
      t.column === ColumnType.PRE_ASSEMBLED ||
      t.group === ColumnGroupType.DETENTIONS ||
      (!!t.releaseProjectionTime && t.isReleaseProjectionWithinCurrentShift) ||
      t.issuedWithinCurrentShift
  );
};

export const filterDashboardPreAssignmentTrucks = (
  isSearching: boolean,
  searchResults: PreTruckSummary[]
): PreTruckSummary[] => {
  if (isSearching) return searchResults;
// Filter Trucks
 return searchResults.filter(
    (t) =>
      t.column === ColumnType.PRE_ASSEMBLED ||
      t.group === ColumnGroupType.DETENTIONS ||
      (!!t.releaseProjectionTime && t.isReleaseProjectionWithinCurrentShift) ||
      t.issuedWithinCurrentShift
  );
};

export const formatMaintenanceLevels = (
  maintenanceInfo: TruckSummary["maintenance"]
): string => {
  const { level1, level2 } = maintenanceInfo ?? {};
  if (!level1 && !level2) return "";
  return `${level1 ?? ""}${level2 ? ` / ${level2}` : ""}`;
};

export const maintenanceTrucksExcelFormat = (
  trucks: TruckSummary[]
): Record<string, string | number>[] => {
  const filteredTrucks = trucks.filter(
    (t) => t.group === ColumnGroupType.MAINTENANCE
  );

  const sortedTrucks = sortTrucks(filteredTrucks, ColumnGroupType.MAINTENANCE);
  return filterDashboardTrucks(false, sortedTrucks).map((truck) => ({
    CAEX: truck.id,
    "NOMBRE OPERADOR PRE-ARMADO": truck.preassembledOper?.name ?? "",
    "UBICACIÓN CAEX": truck.location?.name ?? "",
    "": "",
    "COMENTARIOS MANTENCIÓN": truck.maintenance?.comment ?? "",
    "EMPRESA CONTRATISTA": truck.isCa ? "SI" : "",
  }));
};

export const maintenanceTrucksPreAssignmentExcelFormat = (
  trucks: PreTruckSummary[]
): Record<string, string | number>[] => {
  const filteredTrucks = trucks.filter(
    (t) => t.group === ColumnGroupType.MAINTENANCE
  );

  const sortedTrucks = sortPreAssignmentTrucks(filteredTrucks, ColumnGroupType.MAINTENANCE);
  return filterDashboardPreAssignmentTrucks(false, sortedTrucks).map((truck) => ({
    CAEX: truck.id,
    "NOMBRE OPERADOR PRE-ARMADO": truck.preassembledOper?.name ?? "",
    "UBICACIÓN CAEX": truck.location?.name ?? "",
    "": "",
    "COMENTARIOS MANTENCIÓN": truck.maintenance?.comment ?? "",
    "AVISADO": truck.avisado === 1 ? "SI" : "",
  }));
};

export const detentionTrucksPreAssignmentExcelFormat = (
  trucks: PreTruckSummary[]
): Record<string, string | number>[] => {
  const filteredTrucks = trucks.filter(
    (t) => t.group === ColumnGroupType.DETENTIONS
  );
  const sortedTrucks = sortPreAssignmentTrucks(filteredTrucks, ColumnGroupType.DETENTIONS);
  return sortedTrucks.map((truck) => ({
    CAEX: truck.id,
    "NOMBRE OPERADOR PRE-ARMADO": truck.preassembledOper?.name ?? "",
    "UBICACIÓN CAEX": truck.location?.name ?? "",
    "AVISADO": truck.avisado === 1 ? "SI" : "",
  }));
};

export const detentionTrucksExcelFormat = (
  trucks: TruckSummary[]
): Record<string, string | number>[] => {
  const filteredTrucks = trucks.filter(
    (t) => t.group === ColumnGroupType.DETENTIONS
  );
  const sortedTrucks = sortTrucks(filteredTrucks, ColumnGroupType.DETENTIONS);
  return sortedTrucks.map((truck) => ({
    CAEX: truck.id,
    "NOMBRE OPERADOR PRE-ARMADO": truck.preassembledOper?.name ?? "",
    "UBICACIÓN CAEX": truck.location?.name ?? "",
    "EMPRESA CONTRATISTA": truck.isCa ? "SI" : "",
  }));
};
