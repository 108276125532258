import React, { useCallback, useMemo, useContext, useState, useEffect } from "react";
import { Box, Grid, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import {
  DashboardTrucksData,
  TruckSummary,
  columnTypeTranslation,
  Can,
  Module,
  ErrorMsg,
} from "interfaces";
import { useNavigate, useLocation } from "react-router-dom";
import { TruckGroup, TruckCard } from ".";
import {
  AddTrucksCerroAltoDialog,
  CustomSnackbar,
  Header,
  TruckIcon,
  ExcelIcon,
  TruckGroupSkeleton,
  OperatorAssignmentKpis,
  ScreenContainer,
  ContentPasteRounded,
  ChipGroup,
} from "components";
import { TruckColumn } from "./TruckColumn";
import {
  UserContext,
  InfoSourceContext,
  TrucksDashboardContext,
} from "contexts";
import {
  AppRoute,
  filterDashboardTrucks,
  generateFilteredTrucksData,
  generateTrucksExcel,
} from "utils";
import { useData, useSearch } from "hooks";
import Fuse from "fuse.js";
import { CloseSharp } from "@material-ui/icons";
import InfoIcon from '@material-ui/icons/Info';
import { ShiftOperativeTrucksDialog } from "./ShiftOperativeTrucksDialog";
import { PlannedMaintenancesDialog } from "./PlannedMaintenancesDialog";
import {
  OperatorAssignmentSpeedDial,
  DialAction,
} from "./OperatorAssigmentSpeedDial";
import { PreAssembleTrucksDashboardScreen } from "./PreAssemble/PreAssembleTrucksDashboardScreen"
import { STANDARD_POLLING } from "App";
import { TrucksDashboardPrearmedContext } from "contexts/TruckDashboardPrearmedContext";

const filterOptions: Fuse.IFuseOptions<TruckSummary> = {
  keys: ["id"],
  threshold: 0.1,
  minMatchCharLength: 3,
};

interface DataStatus {
  message: string;
  clicked: number;
  activeView: number;
}

const BYPASS_OUTDATED_DATA =
  process.env.REACT_APP_BYPASS_OUTDATED_DATA === "true";

export const TrucksDashboardScreen: React.FC = () => {
  const classes = useStyles();
  const withSpace = useMediaQuery((theme: Theme) => theme.breakpoints.up(1500));
  const navigate = useNavigate();
  const { isAllowedTo } = useContext(UserContext);
  const { magnetElapsedMinutes, firstLoadingSourceInfo, refetchingSourceInfo } =
    useContext(InfoSourceContext);
  const {
    data: trucks,
    firstLoading: loadingTrucks,
    refetching: refetchingTrucks,
    polling: pollingTrucks,
    refetch: refetchTrucksDashboard,
  } = useContext(TrucksDashboardContext);
  const {
    data: trucksPre,
    firstLoading: loadingTrucksPre,
    refetching: refetchingTrucksPre,
    polling: pollingTrucksPre,
    refetch: refetchTrucksDashboardPre,
  } = useContext(TrucksDashboardPrearmedContext);

  const [openCerroAltoDialog, setOpenCerroAltoDialog] =
    useState<boolean>(false);
  const [openOperativeTrucksDialog, setOpenOperativeTrucksDialog] =
    useState<boolean>(false);
  const [openPlannedMaintenancesDialog, setOpenPlannedMaintenancesDialog] =
    useState<boolean>(false);
  const [selectedChipPreAssembled, setSelectedChipPreAssembled] = useState<number | string>(1);
  const location = useLocation();
  useEffect( () => {
    const queryParams = new URLSearchParams(location.search);
    const tabFromUrl = queryParams.get('tab');
    if (tabFromUrl) {
      setSelectedChipPreAssembled(parseInt(tabFromUrl))
    }
    if (selectedChipPreAssembled === 2 ){
      navigate('?tab=2')
    }
    if (selectedChipPreAssembled === 1 ){
      navigate(AppRoute.TRUCKS_DASHBOARD)
    }
  }, [location.search])

  const {data, firstLoading} = useData<DataStatus>(
    {
        config: "/shift-change/visualization/status/pre-assembled",
        options: {
            useCache: false,
          },
          ...STANDARD_POLLING,
    },
    ErrorMsg.GET_STATUS_SHIFT_CHANGE
);

  const {message, clicked, activeView} = data;
  const [openSpeedDial, setOpenSpeedDial] = useState<boolean>(false);

  const isDataOutdated = useMemo(
    () =>
      !BYPASS_OUTDATED_DATA &&
      !firstLoadingSourceInfo &&
      !refetchingSourceInfo &&
      (magnetElapsedMinutes === null || magnetElapsedMinutes >= 15),
    [firstLoadingSourceInfo, magnetElapsedMinutes, refetchingSourceInfo]
  );

  const [searchValue, setSearchValue, , searchResults] =
    useSearch<TruckSummary>(selectedChipPreAssembled === 2 ? trucksPre : trucks , filterOptions);


  const dashboardTrucksData: DashboardTrucksData = useMemo(
    () =>
      generateFilteredTrucksData(
        filterDashboardTrucks(!!searchValue, searchResults)
      ),
    [searchResults, searchValue]
  );

  const onOpenSpeedDial = useCallback((e: unknown, reason: string) => {
    if (reason === "mouseEnter" || reason === "toggle") setOpenSpeedDial(true);
  }, []);

  const onCloseSpeedDial = useCallback(() => setOpenSpeedDial(false), []);

  const onClickNavigate = useCallback(
    (truck: TruckSummary) => {
      navigate(AppRoute.TRUCK_DETAILS.replace(":equipId", truck.id));
    },
    [navigate]
  );

  const handleOnDialogClose = useCallback(
    (e?: unknown, reason?: "backdropClick" | "escapeKeyDown") => {
      if (reason !== "backdropClick") {
        setOpenCerroAltoDialog(false);
        setOpenOperativeTrucksDialog(false);
        setOpenPlannedMaintenancesDialog(false);
      }
    },
    []
  );

  const handleOnOperativeTrucksDialogClose = useCallback(
    () => setOpenOperativeTrucksDialog(false),
    []
  );

  const handleOnDialogComplete = useCallback(
    () => refetchTrucksDashboard(),
    [refetchTrucksDashboard]
  );

  const handleDownloadExcelClick = useCallback(
    () => generateTrucksExcel(trucks),
    [trucks]
  );

  // Do not display loader when search is active
  const showLoadingHeader = useMemo(
    () => refetchingTrucks || pollingTrucks,
    [pollingTrucks, refetchingTrucks]
  );

  const dashboardActions = useMemo<DialAction[]>(
    () => [
      ...(isAllowedTo(Can.WRITE, Module.OPERATOR_ASSIGNMENT)
        ? [
          {
            name: "Empresa Contratista",
            onClick: () => setOpenCerroAltoDialog(true),
            icon: "EC",
          },
          {
            name: "PM / Pre PM",
            onClick: () => setOpenPlannedMaintenancesDialog(true),
            icon: "PM",
          },
        ]
        : []),
      ...(isAllowedTo(Can.READ, Module.OPERATOR_ASSIGNMENT)
        ? [
          {
            name: "Registro CAEX Armados",
            onClick: () => setOpenOperativeTrucksDialog(true),
            icon: <ContentPasteRounded />,
          },
        ]
        : []),
      ...(isDataOutdated
        ? [
          {
            name: "Descargar",
            onClick: handleDownloadExcelClick,
            icon: <ExcelIcon />,
          },
        ]
        : []),
    ],
    [handleDownloadExcelClick, isAllowedTo, isDataOutdated, selectedChipPreAssembled]
  );

  const renderContent = () => {
    if (selectedChipPreAssembled === 1) {
      return <>
      <ScreenContainer>
        <OperatorAssignmentKpis />
        <Box className={classes.trucksGroupsRoot}>
          {loadingTrucks ? (
            <>
              {Array.from(Array(4).keys()).map((i) => (
                <TruckGroupSkeleton key={i} />
              ))}
            </>
          ) : (
            dashboardTrucksData.map(({ columns, group }) => (
              <TruckGroup key={group}>
                {columns.map(({ trucks, column }) => (
                  <TruckColumn
                    key={column}
                    title={columnTypeTranslation(column, withSpace)}
                    chipText={`${trucks.length}`}
                  >
                    {trucks.map((truck) => (
                      <TruckCard
                        key={truck.id}
                        truck={truck}
                        type={column}
                        groupType={group}
                        onClick={onClickNavigate}
                      />
                    ))}
                  </TruckColumn>
                ))}
              </TruckGroup>
            ))
          )}
        </Box>
        {isDataOutdated && (
          <CustomSnackbar
            open={true}
            onClose={() => { }}
            data={{
              severity: "error",
              text: "Problemas de actualización de datos",
            }}
          />
        )}
        <OperatorAssignmentSpeedDial
          ariaLabel="trucks-dashboard-speed-dial"
          actions={dashboardActions}
          speedDialBaseIcon={<TruckIcon />}
          openIcon={<CloseSharp />}
          onClose={onCloseSpeedDial}
          onOpen={onOpenSpeedDial}
          open={openSpeedDial}
        />
      </ScreenContainer>
      <AddTrucksCerroAltoDialog
        open={openCerroAltoDialog}
        onClose={handleOnDialogClose}
        onComplete={handleOnDialogComplete}
      />
      <ShiftOperativeTrucksDialog
        open={openOperativeTrucksDialog}
        onClose={handleOnOperativeTrucksDialogClose}
      />
      <PlannedMaintenancesDialog
        open={openPlannedMaintenancesDialog}
        onClose={handleOnDialogClose}
      />
      </>
    }

    return <>
      <PreAssembleTrucksDashboardScreen clicked={clicked} searchValueParam={searchValue} searchResultsParam={searchResults} />
    </>

  }

  return (
    <>
      <Header
        elapsedMinutes={magnetElapsedMinutes}
        onSearchQueryChange={setSearchValue}
        searchPlaceholder={"Buscar por CAEX"}
        clearOnBlur
        loading={showLoadingHeader}
      />
      <ScreenContainer>
      <Grid style={{
        display: 'flex',
        flexDirection: 'row',
      }}>
        <Box>
            <ChipGroup 
              value={selectedChipPreAssembled}
              onChange={(a) => setSelectedChipPreAssembled(a)}
              options={[
                {
                  id: 1,
                  name: "ARMADO TURNO ACTUAL",
                },
                {
                  id: 2,
                  name: "CAMBIO DE TURNO",
                  disabled: (activeView === 1 ? false: true)
                }
              ]}/>

        </Box>
        {
          (activeView === 1) ? 
        <Grid container style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
          <Grid item className={classes.messageInfo}>
            <InfoIcon className={classes.infoIcon} />
            {message}
          </Grid>
          </Grid>
          : null
          }
      </Grid>
      {renderContent()}
      </ScreenContainer>
    </>
  );
};

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  const { type, background } = palette;
  const cellBackground =
    type === "light" ? palette.secondary.main : palette.common.white;

  return {
    trucksGroupsRoot: {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: type === "light" ? background.paper : background.default,
      flexGrow: 1,
      paddingBottom: 34,
      marginTop: 34,
    },
    speedDial: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    speedDialIcon: {
      color: palette.common.white,
    },
    infoIcon: {
      lineHeight: "24px",
      marginRight: 10,
      width: 20,
      height: 20,
    },
    actions: {
      backgroundColor: palette.primary.dark,
      color: palette.common.white,
      "&:hover": {
        backgroundColor: palette.primary.dark,
      },
    },
    actionsTooltips: {
      fontSize: theme.typography.body1.fontSize,
      backgroundColor: cellBackground,
      color: palette.getContrastText(cellBackground),
    },
    messageInfo: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignIntems: 'center',
      backgroundColor: '#E7F1F8',
      minWidth: 600,
      height: 32,
      paddingLeft: 50,
      borderRadius: 4,
      color: '#0E4969',
      fontSize: '14px',
      paddingTop: '4px'
    }
  };
});
